// src/templates/logHistory.js
import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import SubCard from "../components/sub-card";
import { LogNavigator } from "../components/log-navigator";

export const query = graphql`
  query {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          frontmatter {
            date(formatString: "YYYY/MM/DD")
            title
            slug
            tag
            thumbnail {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
          body
        }
      }
    }
  }
`;

const LogHistory = ({ data, pageContext }) => {
  // ここで指定された年月のエントリのみをフィルタリング
  const yearMonthlyData = data.allMdx.edges.filter((edge) => {
    const date = new Date(edge.node.frontmatter.date);
    // console.log(date.getFullYear(), date.getMonth() + 1);
    return (
      date.getFullYear() === Number(pageContext.year) &&
      date.getMonth() + 1 === Number(pageContext.month)
    ); // JavaScriptの月は0から始まるので+1して調整
  });

  return (
    <Layout>
      <LogNavigator />

      <div className="lg:flex lg:flex-wrap">
        {yearMonthlyData.map((edge) => {
          return (
            <SubCard
              title={edge.node.frontmatter.title}
              date={edge.node.frontmatter.date}
              src={edge.node.frontmatter.thumbnail.childImageSharp.fluid.src}
              tag={edge.node.frontmatter.tag}
              slug={edge.node.frontmatter.slug}
            />
          );
        })}
      </div>
    </Layout>
  );
};

export default LogHistory;
